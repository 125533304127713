<template>
  <v-row>
    <v-col>
      <!-- Maintenance Mode Announcement -->
      <v-row
        v-if="maintenanceMode"
        class="justify-center ma-0"
      >
        <v-col>
          <v-card
            class="elevation-0"
          >
            <v-card-text>
              <v-row
                class="justify-center"
              >
                <v-col>
                  <v-icon
                    class="pr-3"
                  >
                    info_outline
                  </v-icon>

                  <span>
                    Maintenance Mode is currently enabled.
                    You may encounter minor issues while maintenance is in progress.
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row
        v-if="user"
        class="justify-center align-start"
      >
        <v-col
          class="col-auto mt-2 py-4"
          min-width="300px"
          max-width="450px"
        >
          <welcome-card />

          <network-list />
        </v-col>

        <v-col
          class="col-auto mt-3"
          min-width="300px"
          max-width="450px"
        >
          <release-brief />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'Dashboard',
  components: {
    NetworkList: () => import('src/dashboard/components/NetworkList'),
    ReleaseBrief: () => import('src/dashboard/components/ReleaseBrief'),
    WelcomeCard: () => import('src/dashboard/components/WelcomeCard'),
  },
  data() {
    return {
      maintenanceMode: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
}
</script>
